import { useEffect } from "react";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { useProductSearch } from "@ludens-reklame/rubics-v3-react/product/hooks";
import { Page } from "@ludens-reklame/rubics-v3-sdk/page/types";
import { Card } from "../../internal-components/card/card.js";
import { RichText } from "../../internal-components/richText/RichText.js";
import { CardGrid } from "../../internal-components/cardGrid/cardGrid.js";

interface Props {
  category?: Page;
}

const ProductList: React.FC<Props> = ({ category }) => {
  const page = useStore().context.page;
  const { results, search } = useProductSearch();

  useEffect(() => {
    search(false, {
      categories:
        category?._id || page._id ? [category?._id || page._id] : undefined,
      faceted: false,
    });
  }, [category?._id, page._id]);

  return (
    <div className="productlist-container vs-xl hs mw">
      <CardGrid>
        {results.map((p) => {
          return (
            <Card
              key={p._id}
              image={p.primaryImage}
              title={{ type: "h4", text: p.name }}
              link={{
                url: p.url,
                text: p.name,
                external: false,
              }}
              linkList={p.categories.map((category) => ({
                url: category.url,
                title: category.title,
                external: false,
              }))}
              btnText="Kjøp"
            >
              {p.description && <RichText html={p.description} />}
              {p.priceEntries.map((e) =>
                e.type === "loyaltyProgram" ? (
                  <p className="price-text b2">
                    Medlemspris: {p.pricePrefix} {e.priceText} {p.priceSuffix}
                  </p>
                ) : (
                  <p className="b2">
                    Ordinær pris: {p.pricePrefix} {e.priceText} {p.priceSuffix}
                  </p>
                )
              )}
              {p.priceEntries.length === 0 && (
                <p className="b2">
                  Ordinær pris: {p.pricePrefix} {p.priceText} {p.priceSuffix}
                </p>
              )}
            </Card>
          );
        })}
      </CardGrid>
    </div>
  );
};
export default ProductList;
